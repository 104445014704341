.person {
  margin-bottom: 1rem;
  .cell.shrink {
    max-width: 29.305%;
  }
}
.person-image {
  border: 2px solid $light-gray;
  cursor: pointer;
}
.person-name {
  text-transform: none;
  font-size: 1rem;
  margin-top: 4%;
}
.person-position {
  color: $dark-gray;
}
.person-actions a {
  color: $primary-color;
  font-style: italic;
}

.reveal {
  .person-image {
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
  .person-name {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 0;
    color: $primary-color;
  }
}
