.entry-nav {
  padding: 1rem 0;
  background: get-color(tan);
  text-transform: uppercase;
  font-size: .9em;
  @include breakpoint(medium) {
    font-size: 1.25rem;
  }
  .invisible {
    visibility: hidden;
  }
  .menu > li {
    border-left: 1px solid #bfaf82;
    &:first-child {
      border: none;
    }
    & > a {
      display: flex;
      color: $white;
      &:hover {
        color: $secondary-color;
      }
      & + .invisible {
        display: none;
      }
      svg {
        align-self: center;
        margin-right: 0;
        margin-bottom: .5em;
        font-size: 2rem;
      }
    }
  }
}
