@mixin animate-spinner {
  transform: translateZ(0);
  animation: spinning 1.1s infinite linear;

  @keyframes spinning {
    0%   { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
}

#calendar {
  position: relative;
  overflow: auto;
  width: 100%;
  &.loading {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($white, .8);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      width: 6em;
      height: 6em;
      display: block;
      font-size: 10px;
      text-indent: -9999em;
      border-top: .5em solid rgba($secondary-color, 0.2);
      border-right: .5em solid rgba($secondary-color, 0.2);
      border-bottom: .5em solid rgba($secondary-color, 0.2);
      border-left: .5em solid $primary-color;
      box-shadow: 0 0 5px rgba(0,0,0,.5);
      @include animate-spinner;
    }
  }
}

.calendar {
  // width: 105vh;
  border-spacing: 0;
  table-layout: fixed;
  th {
    vertical-align: middle;
  }
  .calendar-button {
    margin: 0;
    font-style: italic;
    color: $primary-color;
    font-size: 1.5rem;
    &:hover {
      color: $secondary-color;
    }
    @include breakpoint(medium) {
      font-size: 1rem;
    }
  }
  .calendar-weekday {
    padding: 2rem .5rem .5rem .5rem;
    font-weight: 700;
    width: 14%;
    text-align: center;
  }
  tr:nth-child(even) {
    background: $white;
  }
  .calendar-day {
    position: relative;
    padding: 0;
    height: 15vh;
    width: 15vh;
    vertical-align: top;
    border: 1px solid $light-gray;
  }
  .day-head {
    padding: 25% 0 50% 0;
    text-align: center;
    @include breakpoint(medium) {
      padding: 0 4px;
      font-size: .875rem;
      text-align: right;
    }
    a {
      display: block;
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      padding: 25% 0 50% 0;
      @include breakpoint(medium) {
        position: static;
        padding: 0;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        width: .5rem;
        height: .5rem;
        display: block;
        background: $primary-color;
        @include breakpoint(medium) {
          display: none;
        }
      }
    }
  }
  .day-body {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  .event {
    font-size: .875rem;
    padding: 4px 8px;
    line-height: 1.2;
  }
  .event-title {
    color: $primary-color;
    &:hover {
      color: $secondary-color;
    }
  }
  .event-time {
    font-size: .685rem;
    color: $dark-gray;
  }
}


// .fc {
//   width: 100%;

//   table {
//     margin: 0;
//   }

//   thead {
//     background: transparent;
//   }

//   .fc-day-number {
//     font-weight: 400;
//   }

//   .fc-head-container {
//     border-width: 0;
//     padding: .5rem 0;
//   }

//   .fc-day-header {
//     border-width: 0;
//     font-size: 1.25rem;
//   }

//   .fc-event {
//     border-radius: 0;
//     border: none;
//   }

//   .fc-button {
//     color: $primary-color;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     font-size: .875rem;
//   }

//   td.fc-today {
//     background: lighten($secondary-color, 60%);
//   }
// }
