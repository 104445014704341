.news {
  position: relative;
  padding: 6vh 0;
}
.news.single {
  padding-bottom: 0;
  & > .grid-container > .cell:after {
    bottom: 0;
  }
}
.news > .grid-container > .cell {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -6vh;
    width: 100%;
    border-bottom: 1px solid $light-gray;
  }
}
.news-body {
  line-height: 1.25;

  img.float-left,
  img.float-right {
    max-width: 100%;
    max-height: 50vh;
    display: block;
    margin: 0 auto 1rem auto;

    @include breakpoint(large) {
      max-width: 40%;
      margin-bottom: 1.5rem;
    }
  }

  img.float-left {
    float: none !important;
    @include breakpoint(large) {
      float: left !important;
      margin-right: 3rem;
    }
  }

  img.float-right {
    float: none !important;
    @include breakpoint(large) {
      float: right !important;
      margin-left: 3rem;
    }
  }
}
.news-image + .news-body {
  @include breakpoint(medium) {
    margin-left: 1rem;
  }
}

.news-title {
  font-size: 1rem;
  color: $secondary-color;
  text-transform: none;
  margin-bottom: .25rem;
}
.news-link {
  font-size: .875rem;
  font-style: italic;
}
.news-image {
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    width: 275px;
    margin-right: 4%;
    max-width: 33%;
  }
  a {
    display: block;
  }
  img {
    border: 2px solid $light-gray;
    display: block;
    width: 275px;
    max-width: 100%;
  }
}
