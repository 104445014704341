.card {
  position: relative;
  h1, h2, h3, h4, h5, h6 {
    color: get-color(tan);
    @include get-header-size(h5);
  }
  &:hover {
    color: $card-font-color;
  }
}

.card-section {
  position: relative;
  &.has-action {
    padding-bottom: 2rem;
  }
}

.card-action {
  color: get-color(tan);
  position: absolute;
  transition: all .3s;
  display: block;
  @include breakpoint(medium) {
    bottom: -4rem;
  }
}

.card-media {
  overflow: hidden;
}

.card-media > img {
  display: block;
  opacity: 1;
  transform: scale(1);
  transition: all .3s;
}

.card:hover {
  .card-media > img {
    transform: scale(1.05);
    opacity: .4;
  }
  .card-action {
    bottom: 1rem;
  }
  .card-caption {
    background: rgba($secondary-color, .9);
    &:before {
      right: .75rem;
    }
  }
}

.cell > .card {
  height: 100%;
}

.card-caption {
  background: rgba($secondary-color, .8);
  padding: 1rem 2rem 1rem 1rem;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: $white;
  }
  &:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%23d1d2d4' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ");
    background-size: contain;
    height: 1.25rem;
    width: .6875rem;
    margin-top: -.625rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    transition: all .3s;
  }
}
