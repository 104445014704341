.site-foot {
  z-index: 1;
}
.site-foot-top {
  padding: 6vh 0;
  color: $white;
  @include pattern;
  background-color: $secondary-color;
  border-top: 10px solid get-color(tan);

  h4 {
    color: get-color(tan);
  }

  a {
    color: $white;
    &:hover {
      color: get-color(tan);
    }
  }

  .input-group .button {
    width: 3.1875rem;
    padding: 0;
    font-size: 1.5rem;
  }
}

.site-foot-social {
  justify-content: center;
  @include breakpoint(medium) {
    justify-content: flex-start;
  }
  h4 {
    margin-bottom: .25rem;
    @include breakpoint(medium) {
      margin: 0;
    }
  }
  .icon {
    font-size: 2rem;
    margin-left: .75rem;
  }
}

.site-foot-location {
  font-size: .875rem;
  margin-top: 1rem;
  text-align: center;
  @include breakpoint(medium) {
    text-align: right;
  }
  p {
    margin-bottom: .5rem;
  }
}

.site-foot-links {
  text-align: center;
  font-size: 1.125rem;
  @include breakpoint(medium) {
    margin-top: .75rem;
    margin-right: 2rem;
    text-align: left;
  }
  margin-top: 1rem;
  nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
    & > .cell {
      margin-bottom: 0.65rem;
    }
  }
}

.site-foot-bottom {
  color: $white;
  background-color: $primary-color;
  border-top: 5px solid $white;
  padding: 1rem .5rem;
  text-align: center;

  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .grid-x {
    justify-content: center;
    @include breakpoint(medium) {
      justify-content: space-between;
    }
  }

  .tripadvisor {
    color: $white;
    font-size: 1rem;
    text-decoration: none;
    &:hover {
      color: get-color(tan);
    }
    @include breakpoint (medium) {
      font-size: 1.5rem;
    }
    text-transform: uppercase;
    .icon {
      position: relative;
      font-size: 1.25em;
      top: .25em;
      margin-left: .25em;
    }
  }
}
