.event {
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 6vh 0;
  color: $black;
}
.event > .grid-container > .cell {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -6vh;
    width: 100%;
    border-bottom: 1px solid $light-gray;
  }
}
.event-date {
  display: none;
  @include get-header-size(h5);
  padding: .55em .85em .5em .85em;
  color: $white;
  background: get-color(gold);
  line-height: 1;
  text-align: center;
  @include breakpoint(medium) {
    display: block;
  }
}
.event-date-month {
  text-transform: uppercase;
}
.event-date-day {
  font-size: 1.5em;
  font-weight: 700;
}
.event-body {
  line-height: 1.25;
  @include breakpoint(medium) {
    margin-left: 1rem;
  }
}
.event-title {
  font-size: 1rem;
  color: $secondary-color;
  text-transform: none;
  margin-bottom: .25rem;
}
.event-link {
  font-size: .875rem;
  font-style: italic;
}
.event-image {
  width: 275px;
  max-width: 100%;
  border: 2px solid $light-gray;
  display: block;
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    max-width: 40%;
    float: right;
    margin-left: 4%;
  }
}

.event.sticky {
  background-image: url('../images/events/featured.jpg');
  color: $white;
  & > .grid-container > .cell:after {
    display: none;
  }
}

.event.bg-image {
  .event-title {
    color: get-color(tan);
  }
  .event-image  {
    border-color: $secondary-color;
  }
}

.reveal > .event {
  padding: 0;
  border-bottom: none;
}
