.sharer {
  align-items: center;
  margin: 1rem 0;
  svg {
    font-size: 2.5rem;
    margin-left: 1.25rem;
  }
}
.sharer-title {
  color: $primary-color;
  margin: 0;
  font-weight: normal;
}
