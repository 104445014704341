.off-canvas {
  a {
    color: $white;
  }
  .icon {
    fill: get-color(tan);
  }
  .title {
    padding: 1rem 1rem 0 1rem;
    color: get-color(tan);
    font-size: .875em;
  }
  .submenu-toggle {
    &:after {
      border-top-color: get-color(tan);
    }
    &[aria-expanded='true'] {
      background:rgba(get-color(tan), 0.5);
      outline: none;
    }
    &:focus {
      outline: none;
      &:after {
        border-top-color: $white;
      }
    }
  }
  .off-canvas-scroll {
    @include pattern;
    padding-bottom: 1rem;
  }
  .close-button {
    opacity: 0; // Hide close button
  }
}

[data-whatinput='mouse'] .off-canvas a,
[data-whatinput='touch'] .off-canvas a {
  outline: 0;
}

