.gallery-image {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 2rem;
  color: $white;

  &:hover {
    color: $white;
    svg {
      right: .5rem;
    }
  }

  svg {
    position: absolute;
    right: -2rem;
    bottom: .5rem;
    transition: all .3s;
  }
}
