.orbit {
  height: 28vh;
  background: $secondary-color;
}

.orbit-slide {
  height: 28vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img, figure {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: none;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.orbit-container {
  position: relative;
  transform: scale(1.15);

  &.fixed {
    overflow: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform-origin : 50% 0%;
  }
}

.orbit-icons {
  position: absolute;
  z-index: 3;
  bottom: -2px;
  width: 100%;
  color: $white;
  & > .grid-x {
    align-items: flex-end;
    text-align: center;
    line-height: 1;
    font-size: .5rem;
    margin: 0 auto;
    max-width: 1640px;
    @include breakpoint(medium) {
      font-size: .8rem;
    }
    @include breakpoint(large) {
      font-size: 1rem;
    }
    .cell:nth-child(odd) {
      font-size: 3.5em;
      height: 1em;
    }
    .cell:nth-child(even) {
      font-size: 5.625em;
      height: 1em;
    }
  }
  .icon-dinosaur {
    transform: translateX(-20%);
  }
}

.buy-tickets {
  width: 132px;
  max-width: 100%;
  display: block;
  position: relative;
  top: -64%;
  font-size: .5rem;
  overflow: hidden;
  padding-top: 10%;
  @include breakpoint(xlarge) {
    font-size: .75rem;
  }
}
svg.buy-tickets-icon {
  overflow: visible;
}
.buy-tickets-circle {
  fill: $primary-color;
  stroke: $white;
  stroke-linejoin: round;
  stroke-width: 3px;
}
.buy-tickets-floater,
.buy-tickets-ship {
  fill: $white;
  transform: translateY(0);
  transition: all .3s;
}
.buy-tickets-text {
  position: absolute;
  bottom: 1em;
  left: 0;
  width: 100%;
  text-align: center;
  color: $white;
  font-size: 1.25em;
  text-transform: uppercase;
  transform: translateY(4em);
  transition: all .3s 0s;
  small {
    text-transform: none;
    margin-bottom: 2px;
    display: block;
  }
}
// .buy-tickets:hover {
//   .buy-tickets-circle {
//     fill: $secondary-color;
//   }
//   .buy-tickets-ship {
//     animation-duration: 1.5s;
//     animation-iteration-count: infinite;
//     animation-name: wiggle;
//   }
//   .buy-tickets-floater {
//     transform: translateY(-1.75em);
//   }
//   .buy-tickets-text {
//     transform: translateY(0);
//     transition: all .3s .2s;
//   }
// }
