.title-bar {
  position: relative;
  z-index: 1;
}
.title-bar-title a {
  display: block;
  color: $primary-color;
  font-size: 3rem;
  position: absolute;
  left: .5rem;
  top: 0;
  background: url('../images/texture.jpg');
  padding: 0 .5rem;
  line-height: 1;
}

.site-head {
  position: relative;
  z-index: 1000;
  border-bottom: 5px solid $white;
}
.site-head-top {
  display: block;
  background: $secondary-color;
  font-size: .875em;
  color: $white;
  @include pattern;
  padding: .5rem;
  a {
    color: $white;
    &:hover {
      color: get-color(tan);
    }
  }
  .icon {
    font-size: 2em;
    vertical-align: middle;
    margin-right: .25em;
  }
}
.site-head .icon-logo {
  color: $primary-color;
  font-size: 7.75rem;
}
.site-head-middle {
  @include textured;
  margin-bottom: -1.5rem;
  .menu.logo > li > a {
    position: relative;
    z-index: 1;
    @include textured;
    padding: .75rem;
  }
  .menu.icon-top {
    position: relative;
    top: -.75rem;
    &  > li > a {
      color: $secondary-color;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      display: flex;
      svg {
        position: relative;
        z-index: 1;
        color: $primary-color;
        align-self: center;
        font-size: 2.5rem;
        margin: 0 0 .75rem 0;
      }
      &:before {
        content: '';
        width: 4rem;
        height: 4rem;
        background: get-color(tan);
        border: 1px solid $primary-color;
        border-radius: 50%;
        position: absolute;
        top: .25rem;
        left: 50%;
        margin-left: -2rem;
        transform: scale(0);
        transition: all .3s;
      }
      &:hover {
        &:before {
          transform: scale(1);
        }
        svg {
          fill: $secondary-color;
        }
      }
    }
  }
}
.site-head-bottom {
  background: $primary-color;
  a {
    color: $white;
    &:hover {
      color: $white;
      background-color: #751424;
    }
  }
  .menu li.is-dropdown-submenu-parent > a {
    padding-right: 1rem;
  }
  .menu.dropdown > li.is-dropdown-submenu-parent > a:after {
    display: none;
  }
  .menu li.is-dropdown-submenu-parent > a:after {
    border-color: #751424 transparent transparent;
  }
  .menu li.is-dropdown-submenu-parent > a:hover:after {
    border-color: $primary-color transparent transparent;
  }
  .menu li.is-active > a {
    color: $white;
    background-color: #751424;
  }
  .menu li.is-active .menu li.is-active > a {
    color: $white;
    background-color: $primary-color;
  }
  .menu li.is-active > a:after {
    border-color: $primary-color transparent transparent;
  }
  .submenu {
    margin: 0; // remove nested margin
    min-width: 260px;
    z-index: 10;
    a {
      padding-right: 3rem;
      position: relative;
      &:hover {
        background-color: $primary-color;
        .event-title {
          color: $primary-color;
        }
      }
    }
  }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a {
    &:after {
      content: '';
      height: 16px;
      width: 9px;
      position: absolute;
      top: 50%;
      right: 1.25rem;
      margin-top: -8px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%23d1d2d4' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ");
      transition: all .3s;
    }
    &:hover:after {
      right: 1rem;
    }
  }
}
.site-head-social {
  margin-left: 1rem;
}

