// Base
@import 'settings';
@import 'motion-ui';
@import 'foundation';
@include foundation-everything;

@import '~featherlight/src/featherlight.css';
@import '~featherlight/src/featherlight.gallery.css';
@import "~flatpickr/dist/themes/airbnb";

@import 'mixins';
@import 'icons';
@import 'head';
@import 'foot';
@import 'orbit';
@import 'button';
@import 'section';
@import 'home';
@import 'offcanvas';
@import 'breadcrumbs';
@import 'feature';
@import 'accordion';
@import 'museum-map';
@import 'table';
@import 'card';
@import 'calendar';
@import 'event';
@import 'exhibit';
@import 'job';
@import 'news';
@import 'person';
@import 'reveal';
@import 'form';
@import 'sharer';
@import 'entry-nav';

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

h1, h2, h3, h4 {
  color: $primary-color;
}

h3, h4 {
  font-weight: 400;
}

@each $name, $color in $foundation-palette {
  .text-#{$name} { color: $color; }
}
.text-medium-gray { color: $medium-gray; }
.text-dark-gray { color: $dark-gray; }

/* Hide main navigation before JS loads */
.no-js {
  @include breakpoint(small only) {
    .site-head {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.row.small-up-divided {
  .cell:nth-child(odd) {
    @include breakpoint(medium) {
      padding-right: 2rem;
    }
    @include breakpoint(large) {
      padding-right: 4rem;
    }
  }
  .cell:nth-child(even) {
    @include breakpoint(medium) {
      border-left: 1px solid $light-gray;
      padding-left: 2rem;
    }
    @include breakpoint(large) {
      padding-left: 4rem;
    }
  }
}
.grid-x.medium-up-divided {
  .cell:nth-child(odd) {
    @include breakpoint(large) {
      padding-right: 4rem;
    }
  }
  .cell:nth-child(even) {
    @include breakpoint(large) {
      border-left: 1px solid $light-gray;
      padding-left: 4rem;
    }
  }
}


