.orbit.home {
  height: 52vh;
  .orbit-slide {
    height: 52vh;
  }
  @include breakpoint(medium) {
    min-height: 500px;
    .orbit-slide {
      height: 52vh;
      min-height: 500px;
    }
  }
}

.orbit-hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 475px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 2rem;
  background: rgba($secondary-color, 0.8);
  font-size: 1rem;
  @include breakpoint(medium) {
    font-size: 2rem;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: .3em;
    color: $white;
  }
  .icon-crown {
    fill: get-color(tan);
    font-size: 3.5em;
    margin-bottom: .2em;
  }
  .button {
    font-size: 1rem;
    padding: .5rem 1rem;
    @include breakpoint(medium) {
      padding: 1rem 2rem;
    }
  }
}

.treasure-map {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  top: 3vw;
  bottom: -3vw;
  display: none;
  max-width: 1280px;
  @include breakpoint(medium) {
    display: block;
  }
  &:before {
    content: '';
    background: -moz-linear-gradient(top, rgba($white,1) 40%, rgba($white,0) 100%);
    background: -webkit-linear-gradient(top, rgba($white,1) 40%,rgba($white,0) 100%);
    background: linear-gradient(to bottom, rgba($white,1) 40%,rgba($white,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#50ffffff', endColorstr='#00e5e5e5',GradientType=0 );
    position: absolute;
    width: 50%;
    height: 300px;
    right: -300px;
    top: -3vw;
    z-index: 1;
  }
  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow: visible;
  }
  #ship-floater {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: wiggle;
  }
  @keyframes wiggle {
    0%  { transform: translateY(0) }
    50%  { transform: translateY(-.5em) }
    100% { transform: translateY(0) }
  }
}

@keyframes rotating {
  0% { transform: rotate(0deg) scale(1); }
  10% { transform: rotate(36deg) scale(1.2); }
  20% { transform: rotate(72deg) scale(1); }
  30% { transform: rotate(108deg) scale(1.2); }
  40% { transform: rotate(144deg) scale(1); }
  50% { transform: rotate(180deg) scale(1.2); }
  60% { transform: rotate(216deg) scale(1); }
  70% { transform: rotate(252deg) scale(1.2); }
  80% { transform: rotate(288deg) scale(1); }
  90% { transform: rotate(324deg) scale(1.2); }
  100% { transform: rotate(360deg) scale(1); }
}
#starburst {
  transform-origin: 141.81px 1830.97px;
  animation: rotating 16s linear infinite;
}

.section.introduction {
  position: relative;
  z-index: 3;
  background: -moz-linear-gradient(top, rgba($white,0) 0%, rgba($white,0.75) 100%);
  background: -webkit-linear-gradient(top, rgba($white,0) 0%,rgba($white,0.75) 100%);
  background: linear-gradient(to bottom, rgba($white,0) 0%,rgba($white,0.75) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fefefe', endColorstr='#80fefefe',GradientType=0 );
}

.section.exhibits {
  background: -moz-linear-gradient(top, rgba($white,0.75) 0%, rgba($white,0) 100%);
  background: -webkit-linear-gradient(top, rgba($white,0.75) 0%,rgba($white,0) 100%);
  background: linear-gradient(to bottom, rgba($white,0.75) 0%,rgba($white,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80fefefe', endColorstr='#00fefefe',GradientType=0 );
  position: relative;
  z-index: 3;
  padding: 0;
  margin: 0 auto;
  max-width: 2700px;
  .grid-x {
    flex-wrap: nowrap;
  }
  & > .grid-x {
    .grid-x {
      flex-direction: column;
      .cell {
        padding: 0 5px;
        position: relative;
        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          top: 0;
          left: -5px;
          right: -5px;
          bottom: 0px;
          background: $white;
        }
      }
    }
    .cell:first-child .cell:first-child { padding-left: 0; }
    .cell:last-child .cell:last-child { padding-right: 0; }
  }
  img {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  @include breakpoint (medium) {
    .grid-x {
      align-items: center;
    }
    .grid-x .grid-x {
      flex-direction: row;
    }
    & > .grid-x {
      background: transparent;
    }
    .cell {
      flex: 1 1 auto;
    }
    .cell:first-child .cell:last-child {
      align-self: flex-end;
      margin-bottom: 2rem;
    }
  }
}

.section.events {
  position: relative; // For textured background
  padding-top: 15%;
  margin-top: -10%;
  @include breakpoint(medium) {
    padding-bottom: 14rem; // Space for map
  }
  & .grid-x > .cell {
    position: relative;
    z-index: 2;
  }
  // Texture is set in a pseudo so it's behind the map
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include textured;
  }
}

.upcoming-events {
  background: $white;
  padding: 1.75rem 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    height: 412px;
    overflow: auto;
  }
  .upcoming-events-title {
    color: $primary-color;
    @include get-header-size(h5);
    padding: 0 2rem;
  }

  .event {
    padding: 1rem 2.5rem 1rem 1rem;
    border-bottom: 1px solid #e6e6e6;
    @include breakpoint(medium) {
      padding: 1.5rem 3.5rem 1.5rem 2rem;
    }
    &:hover {
      background: lighten($secondary-color, 55%);
      .event-title {
        color: $primary-color;
      }
      &:before {
        right: .75rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%239e1b30' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ");
      }
    }
    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%23d1d2d4' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ");
      height: 2.25rem;
      width: 1.25rem;
      position: absolute;
      top: 50%;
      right: 1rem;
      margin-top: -1.125rem;
      transition: all .3s;
    }
  }

  .upcoming-events-link {
    display: block;
    color: $white;
    background: $secondary-color;
    padding: .5rem 2.25rem;
    font-size: 1.25rem;
    font-style: italic;
  }
}
