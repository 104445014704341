#map {
  img {
    display: block;
    width: 100%;
  }

  .accordion-content {
    ul {
      margin: 0;
      padding-left: 1.5em;
      font-size: .85rem;
      & > li {
        list-style: disc;
        padding: 0;
        &:before {
          display: none;
        }
      }
    }

    h4 {
      text-transform: none;
      color: $secondary-color;
      font-weight: bold;
    }
  }
}
