.accordion-item {
  border-bottom : 1px solid $light-gray;
}
.accordion-title:before {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%239e1b30' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ");
  background-size: contain;
  height: 1.25rem;
  width: .6875rem;
  margin-top: -.625rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transition: all .3s;
}

.is-active > .accordion-title:before {
  transform: rotate(90deg);
}

.content .accordion-item, .accordion-content  .accordion-item {
  list-style: none;
  padding-left: 0;
  &:before {
    display: none;
  }
}

.accordion-content > .accordion {
  .accordion-item {
    margin: 0;
  }
  .accordion-title {
    color: $secondary-color;
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 32'%3E%3Cpath fill='%235a6a7b' d='M1.44 32l-1.44-1.482 15.155-14.654-15.155-14.341 1.44-1.524 16.741 15.864-16.741 16.136z'%3E%3C/path%3E%3C/svg%3E ")
    }
  }
}
