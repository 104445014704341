table {
  border: 1px solid transparent;
  border-collapse: collapse;
  width: 100%;

  thead, tbody, tfoot {
    border: none;
  }

}
