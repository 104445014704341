.feature {
  padding: 1.75rem 2.75rem 1.5rem 2.5rem;
  color: $white;
  background-color: $secondary-color;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  height: 100%;
  h1 ,h2, h3, h4, h5 {
    color: get-color(tan);
    margin: 0 0 .25rem 0;
    @include get-header-size(h5);
  }
  &.text-right {
    text-align: right;
    background-position: 0 0;
  }
  &.transparent {
    background-color: transparent;
  }
}

.feature.link {
  background-position: 50% 50%;
  display: flex;
  flex-wrap: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  text-shadow: 0 0 10px darken($secondary-color, 25%);
  @include get-header-size(h3);
  position: relative;
  overflow: hidden;
  transition: .3s all;
  width: 100%;
  min-height: 20vh;
  span:first-child {
    transform: scale(1);
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: .3s all;
    &:before {
      content: '';
      background: $secondary-color;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      transition: .3s all;
    }
  }
  span:last-child {
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      width: 1px; // If this is 0, IOS will require a double tap.
      height: 4px;
      background: get-color(tan);
      position: absolute;
      left: 50%;
      bottom: -4px;
      opacity: 0;
      transition: width .3s, left .3s;
    }
  }
  &:hover {
    span:first-child {
      transform: scale(1.1);
      &:before { opacity: .6; }
    }
    span:last-child:before {
      width: 50%;
      left: 25%;
      opacity: 1;
    }
  }
}
