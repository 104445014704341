.job {
  padding: 2rem 0 0 0;
  border-bottom: 1px solid $medium-gray;
  &:first-child {
    border-top: 1px solid $medium-gray;
  }
  @include breakpoint(medium) {
    padding: 3rem 4vh 1rem  4vh;
  }
}
.section .job-title {
  color: $black;
  @include get-header-size(h5);
  text-transform: none;
}
.job-body {
  p, ul, ol {
    margin-bottom: 2rem;
  }
  ul, ol {
    @include breakpoint(medium) {
      margin-left: 3rem;
    }
  }
}
