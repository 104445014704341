.flatpickr-input[readonly] {
  background: $white;
}
.flatpickr-calendar {
  border: 1px solid $medium-gray;
}
.flatpickr-calendar span.flatpickr-day {
  display: block;
  flex: 1 0 auto;
}
.flatpickr-current-month input.cur-year {
  height: 23px;
}
