.button {
  text-transform: uppercase;
}

.button.hollow {
  border-width: 2px !important;
  @each $name, $color in $button-palette {
    &.#{$name}:hover {
      background: $color;
      border-color: $color;
      color: $secondary-color;
    }
  }
  &.secondary:hover {
    color: $white;
  }
}

.button.transparent {
  color: $black;
  background: transparent;
  border-color: transparent;
  text-transform: none;
  padding: 0;
}

.button-icon {
  border: 3px solid $white;
  color: $secondary-color;
  background: transparent;
  text-transform: uppercase;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  @include get-header-size(h4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:hover {
    background-color: rgba($secondary-color, 0.25);
    border-color: $secondary-color;
    color: $secondary-color;
    &:before {
      transform: scale(1.1);
    }
  }
  & > div {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -8.5rem;
      left: 50%;
      margin-left: -4rem;
      width: 8rem;
      height: 8rem;
      background: get-color(tan);
      border: 2px solid $primary-color;
      border-radius: 50%;
      transition: all .3s;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 3;
      top: -8.5rem;
      left: 50%;
      margin-left: -3.9rem;
      width: 7.8rem;
      height: 7.8rem;
      background-image: url('../images/icon-texture.png');
      border-radius: 50%;
      transition: all .3s;
    }
  }
  .icon {
    font-size: 6.25rem;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 2;
    &.icon-tickets {
      position: relative;
      top: .05em;
      left: .05em;
    }
  }
}
