.main-sections {
  position: relative;
  z-index: 1;
  background: $white; // Overlaps fixed obit
}
$padded-sections: (
  bg-image,
  textured
);
.section {
  padding: 6vh 0;

  & + .section {
    padding-top: 0;
  }

  &.wygwam-fix {
    padding: 0;
  }

  @each $section in $padded-sections {
    &.#{$section} {
      padding-top: 6vh;
      & + .section {
        padding-top: 6vh;
        &.small {
          padding-top: 2rem;
        }
      }
      &.small {
        padding-top: 2rem;
      }
    }
  }

  &.textured {
    @include textured;
  }

  &.pagination {
    background: get-color(tan);
    color: $white;
    border-bottom: 6px solid $white;
  }

  &.small {
    margin: 0;
    padding: 2rem 0;
  }

  &.collapse,
  &.image-wide {
    padding: 0;
    & + .section {
      padding-top: 6vh;
      &.small {
        padding-top: 2rem;
      }
    }
  }

  &.image-wide {
    img {
      display: block;
      width: 100%;
    }
  }

  &.image-left,
  &.image-right {
    h1, h2, h3, h4, h5 {
      &:first-child {
        margin-top: 1rem;
      }
    }
    img {
      max-height: 42vh;
      margin-bottom: 1em;
      @include breakpoint(medium) {
        max-height: none;
        margin-bottom: 0;
      }
    }
  }

  &.image-left {
    .cell:last-child {
      @include breakpoint(large) {
        padding-left: 3rem;
      }
    }
  }

  &.image-right {
    .cell:first-child {
      @include breakpoint(medium) {
        order: 2;
      }
    }
    .cell:last-child {
      @include breakpoint(large) {
        padding-right: 3rem;
      }
    }
  }
}

.content, .accordion-content {
  h1 {
    @include get-header-size(h3);
  }
  h2 {
    @include get-header-size(h4);
  }
  h3 {
    @include get-header-size(h5);
  }
  h4 {
    @include get-header-size(h6);
  }
  h2, h3, h4 {
    margin-top: 2rem;
  }
  & > h1:first-child,
  & > h2:first-child,
  & > h3:first-child,
  & > h4:first-child,
  & > h5:first-child {
    margin-top: 0;
  }

  ul {
    list-style: none;
    & > li {
      margin-bottom: .25em;
      padding-left: 1.25em;
      position: relative;
      line-height: 1.5;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: .75em;
        margin-top: -6px;
        border-left: 8px solid $primary-color;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
      }
    }
  }

  ul, ol {
    &.small-up-2,
    &.small-up-3 {
      padding-left: 0;
      column-gap: 1rem;
      li {
        margin-left: 1em;
      }
    }
    &.small-up-2 {
      @include breakpoint(medium) {
        column-count: 2;
      }
    }
    &.small-up-3 {
      @include breakpoint(medium) {
        column-count: 2;
      }
      @include breakpoint(large) {
        column-count: 3;
      }
    }
  }

  img.float-left,
  img.float-right {
    max-width: 25%;
    max-height: 50vh;
    display: block;
    margin: 0 auto 1rem auto;

    @include breakpoint(large) {
      margin-bottom: 1.5rem;
    }
  }

  img.float-left {
    float: none !important;
    @include breakpoint(medium) {
      float: left !important;
      margin-right: 4rem;
    }
  }

  img.float-right {
    float: none !important;
    @include breakpoint(medium) {
      float: right !important;
      margin-left: 4rem;
    }
  }
}

.section.wygwam-fix {
  padding: 0;
  img.float-left,
  img.float-right {
    max-width: 25%;
    max-height: 400px;
  }
}

.js-fade-in {
  opacity: 0;
  transform: translateY(2em);
  transition: ease all .8s;
  transition-delay: .125s;

  &.js-animate {
    opacity: 1;
    transform: translateY(0)
  }
}

.bg-image {
  background-color: $secondary-color;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: $white;
  .content ul > li:before {
    border-left-color: get-color(tan);
  }
  a, h1, h2, h3, h4, h5, h6 {
    color: get-color(tan);
  }
  .content {
    background: rgba($secondary-color, 0.75);
    box-shadow: 0 0px 20px 20px rgba($secondary-color, 0.75);
    border-radius: 4rem;
  }
}
