@mixin get-header-size($header) {
  @each $size, $headers in $header-styles {
    @include breakpoint($size) {
      font-size: rem-calc(map-deep-get($header-styles, $size, $header, font-size));
    }
  }
}

@mixin pattern {
  background-image: url('../images/pattern.png');
  background-size: 180px 236px;
  background-position: 0 20px;
}

@mixin textured {
  background-image: url('../images/texture.jpg');
}
