.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-checklist {
  width: 0.9580078125em;
}

.icon-compass {
  width: 0.7548828125em;
}

.icon-heart {
  width: 1.1494140625em;
}

.icon-logo {
  width: 2.0966796875em;
}

.icon-map {
  width: 1.1865234375em;
}

.icon-pin {
  width: 0.6416015625em;
}

.icon-tickets {
  width: 0.888671875em;
}

.icon-tripadvisor {
  width: 6.619140625em;
}

.icon-chevron-right {
  width: 0.568359375em;
}

.icon-chevron-left {
  width: 0.568359375em;
}

.icon-crown {
  width: 1.4248046875em;
}

.icon-crowned-earth {
  width: 0.640625em;
}

.icon-armour {
  width: 0.8076171875em;
}

.icon-buddha {
  width: 0.78515625em;
}

.icon-dinosaur {
  width: 2.1572265625em;
}

.icon-shell {
  width: 1.2587890625em;
}

.icon-sphinx {
  width: 0.9580078125em;
}

.icon-urn {
  width: 0.7109375em;
}

.icon-vase {
  width: 0.6533203125em;
}

.icon-crown {
  width: 1.4248046875em;
}

.icon-ship {
  width: 1.302734375em;
}

.icon-phone {
  width: 0.7861328125em;
}

.icon-cheers {
  width: 0.7421875em;
}

.icon-calendar {
  width: 0.9892578125em;
}

.icon-pencil {
  width: 0.935546875em;
}

.icon-kit {
  width: 1.1953125em;
}

.icon-mail {
  width: 1.5185546875em;
}

.icon-user {
  width: 1.50390625em;
}

.icon-news {
  width: 1.140625em;
}

.icon-blog {
  width: 1.130859375em;
}

.icon-zoom {
  width: 0.9970703125em;
}

.icon-list {
  width: 1.142578125em;
}

.icon-exhibits {
  width: 1.23828125em;
}
