.reveal {
  border-bottom: 8px solid get-color(tan);
  outline: none;
  @include breakpoint(medium) {
    padding: 2rem;
  }
}

html.with-featherlight body {
  /* disable global scrolling in a cross-browser and device-compliant way */
  position: fixed;
  width: 100%; /* Ensure page width is unaffected by fixed position. */
  height: initial; /* Because height of 100% will break iOS. */
}

.featherlight {
  &:last-of-type {
    background: $reveal-overlay-background;
  }

  .featherlight-content {
    border: none;
    padding: 0;
    background: transparent;
    overflow: visible;
    max-height: 88%;
  }

  .featherlight-close-icon {
    color: $white;
    background: transparent;
    top: -1.5rem;
  }

  .featherlight-next,
  .featherlight-previous {
    top: 50%;
    right: 0;
    left: auto;
    height: 4rem;
    width: 4rem;
    margin-top: -1.5rem;
    background: rgba($secondary-color, .5);
    transition: all .3s;
    span {
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-top: -.75rem;
      text-shadow: none;
      display: inline-block;
    }
    &:hover {
      width: 5rem;
    }
  }

  .featherlight-previous {
    left: 0;
    right: auto;
  }

  &.featherlight-swipe-aware .featherlight-content {
    &:after {
      content: '\00AB swipe between photos \00BB';
      color: $white;
      position: absolute;
      top: 102%;
      right: 0;
      left: 0;
      text-align: center;
      font-size: .75rem;
    }
  }

}
